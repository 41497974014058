*{
  margin:0;
  padding:0;
}

.btn .bx {
  vertical-align: inherit;
  margin-top: -3px;
  font-size: 1.15rem;
}

.form-control {
  height: calc(2.5rem + 2px);
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn {
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.bx.icon-single {
  font-size: 1.5rem;
}

.form-inline .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-inline .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .form-inline .form-control {
    width: 210px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .form-inline .form-control {
    width: 440px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .form-inline .form-control {
    width: 600px;
  }
}


#sidebar {
  background: #fff;
  height: 100%;
  left: -100%;
  top: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  transition: 0.4s ease-in-out;
  width: 84%;
  z-index: 5001;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  padding: 1.25rem 1rem 1rem;
}

#sidebar.active {
  left: 0;
}

#sidebar .sidebar-header {
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 1.5rem;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 40px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px 16px;
  font-size: 1.1em;
  display: block;
  color: #000;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
#sidebar a[aria-expanded="true"] {
  color: #007bff;
  background: #e6f2ff;
  border-radius: 6px;
}

a[data-toggle="collapse"] {
  position: relative;
}

#sidebar .links .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

section {
  padding: 3rem;
  background: white;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: -1;
  width: 100%;
  opacity: 0;
}

.overlay.visible {
  opacity: 1;
  z-index: 5000;
}

/* .mobiHeader .menuActive~.overlay {
    opacity: 1;
    width: 100%;
} */

ul.social-icons {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.social-icons li {
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 0;
}

#sidebar ul.social-icons li a {
  font-size: 24px;
}

.utility-nav {
  background: #e4e4e4;
  padding: 0.5rem 1rem;
}

.utility-nav p {
  margin-bottom: 0;
}

.search-bar {
  position: relative;
  z-index: 2;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.search-bar .form-control {
  width: calc(100% - 45px);
}

.avatar {
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 8px;
}

.avatar.avatar-xs {
  width: 2.25rem;
  height: 2.25rem;
}

.user-dropdown .dropdown-menu {
  left: auto;
  right: 0;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

html, body {
  margin: 0;
  padding: 0;
  width: 100%; 
}


body {
   font-family: sans-serif;
   background-color: #04458E;
   overflow-x: hidden;
}

header {
   width: 100%;    
   /* height: 100vh;
   background:url(https://nov-karl-6.myshopify.com/cdn/shop/files/s-1_2048x.jpg) no-repeat 50% 50%;
   background-size: cover;
   background-position: center; */
   /* background-attachment:fixed ; */
   
}



/* Navbar container */
/* Navbar container */
/* Navbar container */
/* Navbar container */
/* Navbar container */
/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
}

/* Logo */
.logo img {
  height: 80px;
}

/* Navbar links */
.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  color: black;
  padding: 11px 20px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
}

.nav-links .dropdown {
  position: relative;
  display: inline-block;
}

.nav-links .dropbtn {
  padding: 14px 9px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-links a:hover, .dropdown:hover .dropbtn {
  background-color: white;
  color: black;
}
.client_login{
  background: #E37819 !important;
  color: white;
}
.client_login:hover{
  background:#1E3A8A !important;
  color: white !important;
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%; /* Ensure dropdown takes full width */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%; /* Position dropdown below the button */
  left: 0;
  animation: dropDown 0.3s ease-in-out; /* Animation for dropdown */
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Dropdown columns */
.dropdown-content .row {
  display: flex;
  flex-wrap: wrap; /* Ensure columns wrap in smaller screens */
}

.dropdown-content .column {
  flex: 33.33%;
  padding: 10px;
  background-color: white;
}

.dropdown-content .column h5 {
  margin-bottom: 10px;
}

.dropdown-content .column a {
  color: black;
  padding: 8px 0;
  text-decoration: none;
  display: block;
}

.dropdown-content .column a:hover {
  background-color: #1E3A8A;
  color: white;
}

/* Login button */
.login-btn a {
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
}

.login-btn a:hover {
  background-color: #e64a19;
}

/* Toggle button */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle span {
  height: 3px;
  width: 25px;
  background-color: black;
  margin: 4px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: center;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    display: none; /* Hide nav links by default */
  }

  .nav-links.show {
    display: flex; /* Show nav links when toggled */
  }

  .nav-links a {
    padding: 10px;
    width: 100%;
    text-align: left;
  }

  .dropdown {
    position: static; /* Adjust dropdown to static for mobile */
    width: 100%;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    box-shadow: none;
    animation: none; /* Remove animation for static position */
  }

  .dropdown-content .row {
    flex-direction: column;
  }

  .dropdown-content .column {
    width: 100%;
  }

  .login-btn {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .navbar-toggle {
    display: flex;
  }
}

@media (max-width: 480px) {
  .logo img {
    height: 40px;
  }

  .nav-links a {
    padding: 8px;
    font-size: 14px;
  }

  .login-btn a {
    padding: 8px 16px;
  }
}

@keyframes dropDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



/* Carosuel */




.carousel-caption{
   margin-bottom: 10rem;
   margin-right: 30rem;
   line-height: 5rem;
}


.carousel-caption h5 {
   font-size: 4rem;
   color: black;
   font-weight: 499;
}
.carousel-caption p {
   font-size: 0.8rem;
   color: grey;
}


.carousel-btn {
   border: 2px solid black;
   width: 14rem;
   height: 2.7rem;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 13.5rem;
   background: transparent;
}


.carousel_2{
   width: 50%;
   margin-bottom: 12rem;
   margin-left: 30rem;
   line-height: 5rem;
}

.btn_2{
   margin-left: 17.5rem;

}

/* ingedients */



.container_2 {
width: 100%;
height: 44rem;
background-color: aliceblue;
display: flex;
justify-content: center;
align-items: flex-start;
overflow: hidden;

}

.both {
   width: 100%;
   margin: 6% 10% 0 10%;
}

.ingredients{
   line-height: 2.5rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
}


.cards{
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: 1fr;
}

.ingredients_img {
   display: flex;
   flex-direction:column ;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
}

.ingredients_img img {
   border-radius: 50%;
   gap: 1rem;
   
}

.ingredients_img img:hover {
   transform: scale(1.1);
   transition: 0.8s;
}

.ingredients_text {
  margin-top: 1.5rem;
} 




/* products */

.products {
   width: 80%;
   margin: auto 10%;

}


.categories_top{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 3rem;
}

.product_title {
   font-size: 2.7rem;
   font-weight: 700;
   font-family: 'Courier New', Courier, monospace;
   color: #000;
   margin: 3rem 0;
}



.categories {
   padding: 0 5rem;
   border-bottom: 1px solid grey;
}

.categories ul {
   display: flex;
   padding: 0;
   gap: 10rem;
  

   li {
       list-style: none;
       a{
           text-decoration: none;
           color: #000;
           font-size: 1rem;
           
       }
   }
}

/* .categories ul li:hover{
   .categories {
       border-bottom: 2px solid black;
   }
   
} */



.products_container {
       display: grid;
       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     }
.products_cards {
       margin: 1rem;  
       position: relative;
}

.product_img img{
   height: 300px;
   margin-bottom: 1.5rem;
}
 


.button_container_1 {
   position: absolute;
   bottom: -50%;
   left: 50%;
   display: none;

   button {
      background-color: #748899;
      color: #ffffff;
      border: none;
      padding: 10px 20px;
      margin: 5px;
      cursor: pointer;
    }
    
 }
 .products_cards:hover .button_container_1 {
   display: block;
   bottom: 70px;
   transform: translate(70px);
   transition: all 3s ease-in-out;

 }


/* 
   &:hover {
       transform: scale(1.2);
       border-radius: 10px;
       border: 1px solid black;
       transition: ease 1s ;


   } */

 /* grid iamges */

 .featured {
   padding: 0;
   margin-top: 3rem;
   border-top: 1px solid grey;
    
 }
.feat {
   margin-top: 3rem;
   text-align: center;
   margin-bottom: 3rem;
}

 .img_grids {
   display: grid;
   grid-gap: 15px;
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: repeat(2, minmax(200px, 1fr));
   margin: 0 1.5rem;
   height: 750px;


   .grids {
       position: relative;
       overflow: hidden;
   

   img {
       position: absolute;
       top: 0;
       left: 0;
       height: 100%;
       object-fit: cover;        
       transition: ease 3s all; 

   }
   &:hover {
       img {
           transform: scale(1.2);
       }
   }
   &:nth-child(2) {
       grid-column: span 2;
       grid-row: span 2;

     
 }


}
}


.reviews {
   height: 700px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   background-color: #DCE7E0;
   margin-top: 5rem;
}


.review {
   float: left;
   font-size: 2.5rem;
   font-weight: 100;
   margin: 5.5rem 6rem 0 0;
}

.footer{
   width: 100%;
   height: 430px;
   background-color: #000;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   border-bottom: 1px solid grey ;

}

.footer-layout {
display: grid;
grid-template-columns: 2fr 1fr 1fr 0.5fr;
gap: 5rem;
}

.address {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   gap: 3rem;
}
.name {
   font-size: 1.6rem;
   font-weight: 600;
}

.address_details  {
font-size: 1.2rem;
line-height: 2.4rem;
}

.company, .help, .store {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 4.2rem;
}
.name_company, .help_text, .store_text {
   font-size: 1.5rem;
}


.last_footer {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #000;
   color: #fff;
   

}

.contain {
  
   margin: 1.7rem 2rem;   
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 15rem;
   padding: 0;
   
   img {
      background-size: fit;
   }
}




/*  */
/*  */
/*  */
.fa-bag-shopping {
   position: relative;
}
 
.count {
position: absolute;
width: 20px;
border-radius: 50%;
border: 1px solid black;
margin-top: -42px;
margin-left: 6.2rem;
background-color: rgb(128, 222, 229);
}






.item-cart {
   position: fixed;
   height: 100vh;
   width: 100%;
   right: -100%;
   top: 0;
   background-color: #f3f4f6;
   text-align: center;
   padding-top: 80px;
   transition: all 0.3s ease;
   z-index: 998;
   overflow-y: auto;
}
.item-cart.active {
   right: 0;
 
}


#buy {
   background-color: #d1b3b3;
   border-radius: 10px;
   min-width: 5rem;
   padding: 10px;
   min-height: 2rem;
   margin-top: 2rem;
   font-weight: 700;
   cursor: pointer;
}


.cart-item {
   display: flex;
   justify-content: space-around;
   align-items: center;
   border-bottom: 1px solid black;
   margin-top: 2px;

}


.cart-item img {
   width: 100px;
   border-radius: 50%;
   border: 1px solid black;
}

.foot {
   margin-top: 2rem;
   border-bottom: 1px solid black;
}
 .fa-bag-shopping, .count {
   z-index: 999;
}


.buy {
   border-radius: 10px;
   width: auto;
   margin-top: 5px;
}
/* .w-6{
  margin-top: -250px;
} */
/* @media (max-width: 768px) {
 * {
    overflow-x: hidden;
  }
} */
@media (max-width: 768px) {
  .nav_bar_responsive{
     margin-left: 10px;
   }
 }
 .nav_bar_responsive{
  font-size: 14px !important;
 }
 @media (max-width: 768px) {
  .heading_res {
    text-align: left !important;
    margin-left: 10px;

  }
}

.heading_res {
  text-align: center;
  font-size: 17px;
}


.p:hover {
  color: blue;
}

.bg-slate-200 {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: #fff; /* Set text color to white */
}
.text-dark-500 {
  color: #fff; /* Adjust main text color for visibility */
}
.text-gray-500 {
  color: #ccc; /* Adjust secondary text color for readability */
}
.text-indigo-500 {
  color: #b3bcf5; /* Adjust accent text color */
}

.slide-text:hover {
  animation: slideText 0.5s forwards;
}

@keyframes slideText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10px);
  }
}
@media (max-width: 768px) {
  .read_more {
   overflow-y: hidden !important;

  }
}


/* sidebar css */

.Phone_btn {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.Whatsapp_btn {
  position: fixed;
  bottom: 0px;
  left: 20px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}