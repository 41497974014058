.btn1{
    background-color:  #E37819;

   
    border-radius: 10px;
    padding: 10px;
    color: white;

}
.btn1:hover{
    background: #1E3A8A;
}
/* .nav_responsive{
    margin-top: 3px;
} */
