@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #04458E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.absolute {
  position: absolute;
}
.top-full {
  top: 100%;
}
.icon-bars {
  position: fixed;
  right: 0px;
  z-index: 999;
  top: 50%;
  -webkit-transform: translateY(-68%);
  -ms-transform: translateY(-68%);
  transform: translateY(-50%);
}

.icon-bars a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 12px;
}

.icon-bars a:hover {
  background-color: white;
  color: #E37819;
}

.facebook {
  background: #04458E;
  color: white;
}

.twitter {
  background: #04458E;
  color: white;
}

.instagram {
  background: #04458E;
  color: white;
}

.pinterest {
  background: #04458E;
  color: white;
}

.linkedin {
  background: #04458E;
  color: white;
}

.whatsapp {
  background: #04458E;
  color: white;
}

.youtube {
  background: #04458E;
  color: white;
}

@media(max-width:991px) {
  .middle {
      text-align: center;
  }
}

@media(max-width:700px) {
  .growth {
      width: 70% !important;
  }

  .icon-bars {
      position: absolute !important;
      right: 0px;
      z-index: 999;
      top: 50%;
      -webkit-transform: translateY(-68%);
      -ms-transform: translateY(-68%);
      transform: translateY(-50%);
  }
}
.services:hover{
   background: #E37819;
   color: white !important;
}
.service{
  color: black;
}
@media(min-width: 1024px){
 .sales{
  font-size: 12px;
 }

}
/* @media(min-width: 1024px){
  .sales{
    font-size: 12px;
   }
 }
  */
  @media only screen and (max-width: 600px) {
    .flex-shrink-0 {
      width: 60%;
    }
  }

  .inline-flex.items-center.justify-center.p-2.text-black.rounded-md.lg\\:hidden {
    position: relative; /* Ensure it's positioned relative to the header */
    z-index: 10; /* Make sure it appears above other elements */
  }
  
  svg.w-6.h-6 {
    display: block; /* Ensure the icons are displayed */
    width: 24px;
    height: 24px;
    stroke-width: 2px;
  }
  @media only screen and (max-width: 600px) {
    body {
      overflow-x: hidden;
    }
  }

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.navbar-logo {
  height: 50px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.navbar-menu li {
  margin: 0 15px;
  position: relative;
}

.navbar-menu a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  padding: 8px 12px;
}

.navbar-menu a:hover {
  background-color: #f0f0f0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.client-login {
  background-color: #0033cc;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.client-login:hover {
  background-color: #0022aa;
}


.search_btn {
  position: fixed;
  left: 0px;
  z-index: 999;
  top: 50%;
  -webkit-transform: translateY(-68%);
  -ms-transform: translateY(-68%);
  transform: translateY(-50%);
}

.search_btn a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  background:#E37819;
  font-weight: bold;
  font-size: 15px;
}

.search_btn a:hover {
  background-color:#04458E;
  color: white;
}
@media (max-width: 768px) {
  .dropdown-content {
  width: 100px !important;

  }
}
