section{
  padding: 5% 10%;
}

.main-home {
  width: 100%;
  height: 100vh;
  /* background-color: #f0eded; */
  background-color: #fff;
  /* background-image: url(../images/banner-3.png); */
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
}
.main-text h1 {
  color: #000;
  font-size: 60px;
  text-transform: capitalize;
  line-height: 1.1;
  font-weight: 600;
  margin: 6px 0 10px;
}

.main-text p {
  color: #333c56;
  font-size: 20px;
  font-style: italic;
  margin-bottom: 20px;
}

.main-btn {
  display: inline-block;
  color: #111;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  border: 2px solid #111;
  padding: 12px 25px;
  transition: all .42s ease;
  text-decoration: none;
}

.main-btn:hover {
  background-color: #000;
  color: #fff;
}
.main-btn i {
  vertical-align: middle;
}

.para1 {
  color: blue;
}
